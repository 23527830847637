import * as React from 'react';
import { useEffect } from 'react';
import { useIsClientSide } from '../../../../utils/hooks';

interface NeededAppConfigurations {
    enableRedirectOnCookie?: Boolean;
    pageToRedirectOn?: string;
    redirectsOnFrontPage?: redirectObject[];
    cookieExpiration?: number;
}

interface redirectObject {
    mappingToUrl: string;
    mappedFrom: string[];
}

interface HeaderHelperProps {
    config: NeededAppConfigurations; //App config is type on any, thus own type is given
    request: any; //is any since not exposed from msdynamics modules
}

export const HeaderHelper = ({ config, request }: HeaderHelperProps) => {
    return (
        <>
            <CookieHandlerHelper config={config} request={request} />
        </>
    );
};

interface CookieHandlerHelperProps {
    config: NeededAppConfigurations; //App config is type on any, thus own type is given
    request: any; //is any since not exposed from msdynamics modules
}

const CookieHandlerHelper = ({ config, request }: CookieHandlerHelperProps) => {
    const isClient = useIsClientSide();
    const { enableRedirectOnCookie = false, pageToRedirectOn = '', redirectsOnFrontPage = [], cookieExpiration = 0} = config;
    useEffect(() => {
        //Clause guards
        if (!isClient) {
            return;
        }

        const isConsentGiven = request.cookies.isConsentGiven();
        if (!isConsentGiven && !enableRedirectOnCookie) {
            return;
        }
        
        const { pathname } = window.location;
        const cookieName = 'onlyGenderCategory';
        if (pathname === pageToRedirectOn) {
            //If cookie exists, redirect to the page
            if(getCookie(cookieName) !== ''){
                const cookieContent = getCookie(cookieName);
                window.location.replace(cookieContent);
            }
        } else{
            //Set cookie
            const subPaths = pathname.split('/').filter(path => path != '');

            const mappingTo = redirectsOnFrontPage.find(redirect =>
                redirect.mappedFrom.some(fromMapping => subPaths.some(subPath => subPath == fromMapping))
            );
            if (mappingTo == undefined) {
                return;
            }

            const currentDate = new Date();
            currentDate.setDate(currentDate.getDate() + cookieExpiration);
            const expirationDateInUtc = currentDate.toUTCString();
            clearCookie(cookieName);
            document.cookie = `${cookieName}=${mappingTo.mappingToUrl}; expires=${expirationDateInUtc};`;
            return;
        }
    }, [isClient]);
    return <></>;
};

const getCookie = (cname: string) => {
    let name = cname + '=';
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(';');
    for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) == ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
            return c.substring(name.length, c.length);
        }
    }
    return '';
};
const clearCookie = (cookieName: string) => (document.cookie = `${cookieName}=; expires=Thu, 01 Jan 1970 00:00:00 UTC;`);
